import React from 'react';

import { Container, Grid, Typography } from '@material-ui/core';
import { ProfessionalServicesCard } from './ProfessionalServicesCard';

export const ProfessionalServicesBody = ({ cards, header }) => {
	return (
		<Container>
			{/* <Grid container justifyContent='center'>
				<Typography
					variant='h2'
					component='h1'
					color='primary'
					style={{ padding: '4rem 0' }}>
					{header}
				</Typography>
			</Grid> */}
			<div style={{ paddingBottom: '4rem' }}>
				{cards.map((card, index) => (
					<ProfessionalServicesCard card={card} key={index} />
				))}
			</div>
		</Container>
	);
};
