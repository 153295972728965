import React, { useContext } from 'react';
import { Link } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	button: {
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem',
		},
		[theme.breakpoints.down('xs')]: {
			lineHeight: 1.2,
      marginLeft: '0 !important',
		},
	},
}));

export const FeatureButton = ({
	ctaLink,
	internalLink,
	iosLink,
	qbLink,
	ctaText,
	routing,
	accentColor,
	marketing,
}) => {
	const { handleModalClick } = useContext(FormModalContext);

	const lg = useMediaQuery('(max-width: 1280px)');
	const xs = useMediaQuery('(max-width: 481px)');

	const classes = useStyles();
	return (
		<Grid container item>
			{ctaLink ? (
				internalLink ? (
					<Link to={`${ctaLink}`} style={{ textDecoration: 'none' }}>
						<Button
							variant='contained'
							size='large'
							className={classes.button}
							// onClick={handleModalClick}
							style={{
								background: accentColor,
								color: 'white',
								marginLeft: !marketing && '1rem',
								//marginTop: lg && (iosLink || qbLink) ? '2rem' : '1rem',
								marginTop: lg && qbLink ? '2rem' : '1rem',
								padding:
									lg && (!iosLink || !qbLink)
										? '12px 32px'
										: xs && (!iosLink || !qbLink)
										? '8px 24px'
										: '16px 32px',
							}}>
							{ctaText}
						</Button>
					</Link>
				) : (
					<a href={ctaLink} target='_blank' style={{ textDecoration: 'none' }}>
						<Button
							variant='contained'
							size='large'
							className={classes.button}
							// onClick={handleModalClick}
							style={{
								background: accentColor,
								color: 'white',
								marginLeft: !marketing && '1rem',
								//marginTop: lg && (iosLink || qbLink) ? '2rem' : '1rem',
								marginTop: lg && qbLink ? '2rem' : '1rem',
								padding:
									lg && (!iosLink || !qbLink)
										? '12px 32px'
										: xs && (!iosLink || !qbLink)
										? '8px 24px'
										: '16px 32px',
							}}>
							{ctaText}
						</Button>
					</a>
				)
			) : ctaText ? (
				<Button
					variant='contained'
					size='large'
					className={classes.button}
					onClick={handleModalClick}
					style={{
						background: accentColor,
						color: 'white',
						marginLeft: !marketing && '1rem',
						//marginTop: lg && (iosLink || qbLink) ? '2rem' : '1rem',
						marginTop: lg && qbLink ? '2rem' : '1rem',
						padding:
							lg && (!iosLink || !qbLink)
								? '12px 32px'
								: xs && (!iosLink || !qbLink)
								? '8px 24px'
								: '16px 32px',
					}}>
					{ctaText}
				</Button>
			) : null}
		</Grid>
	);
};
